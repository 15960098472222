.seeClassCurriculumParameterInfo .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.seeClassCurriculumParameterInfo .el-form .form-item {
  width: 100%;
  padding-left: 10rem;
}
.seeClassCurriculumParameterInfo .el-form .padd0 {
  padding-left: 0;
}
.seeClassCurriculumParameterInfo .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.seeClassCurriculumParameterInfo .el-textarea .el-textarea__inner {
  resize: none;
}
.seeClassCurriculumParameterInfo .paraCompleteConditionInput {
  display: inline-block;
}
.seeClassCurriculumParameterInfo .paraCompleteConditionInput .el-input {
  display: inline-block;
  width: 40% !important;
  margin: 0 10px;
  height: 32px;
}
.seeClassCurriculumParameterInfo .el-input.is-disabled .el-input__inner {
  background: transparent;
}
.seeClassCurriculumParameterInfo .el-radio__input.is-disabled .el-radio__inner,
.seeClassCurriculumParameterInfo .el-radio__input.is-disabled.is-checked .el-radio__inner {
  background: transparent;
}
